<template>
  <v-data-table
    :mobile-breakpoint="0"
    :headers="userOrdersTableHeaders"
    :items="numberedOrders"
    :search="$store.state.search"
  >
    <template v-slot:[`item.no`]="{ item }">
      {{ item.no }}
    </template>
    <template v-slot:[`item.id`]="{ item }">
      <router-link :to="{ name: 'Order', params: { id: item.id } }">{{
        item.id || ""
      }}</router-link>
    </template>

    <template v-slot:[`item.title`]="{ item }">
      <router-link :to="{ name: 'Order', params: { id: item.id } }">{{
        item.title || ""
      }}</router-link>
    </template>
    <template v-slot:[`item.client_name`]="{ item }">
      <router-link
        v-if="item.client_id"
        :to="{ name: 'ClientProfile', params: { id: item.client_id } }"
        >{{ item.client_name || "" }}</router-link
      >
    </template>

    <template v-slot:[`item.status_desc`]="{ item }">
      <v-chip outlined color="primary" small>
        {{ item.status_desc || "" }}
      </v-chip>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <router-link
        class="
          white--text
          v-btn v-btn--contained v-btn--fab v-btn--round
          theme--light
          v-size--small
        "
        :to="{ name: 'Order', params: { id: item.id } }"
      >
        <v-icon class="primary--text">mdi-eye</v-icon>
      </router-link>
    </template>
  </v-data-table>
</template>


<script>
import { mapState } from "vuex";
export default {
  props: {
    orders: {
      type: Array,
    },
  },
  computed: {
    ...mapState("orders", ["userOrdersTableHeaders"]),
    numberedOrders() {
      return this.orders.map((item, index) => ({ ...item, no: index + 1 }));
    },
  },
};
</script>