<template>
  <v-data-table
    :mobile-breakpoint="0"
    :headers="editorOrdersTableHeaders"
    :items="orders"
    :search="$store.state.search"
  >
    <template v-slot:[`item.id`]="{ item }">
      <router-link :to="{ name: 'Order', params: { id: item.id } }">{{
        item.id || ""
      }}</router-link>
    </template>

    <template v-slot:[`item.title`]="{ item }">
      <router-link :to="{ name: 'Order', params: { id: item.id } }">
        <span v-html="item.title"></span>
      </router-link>
    </template>
    <template v-slot:[`item.client_name`]="{ item }">
      <router-link
        v-if="item.client_id"
        :to="{ name: 'ClientProfile', params: { id: item.client_id } }"
        >{{ item.client_name || "" }}</router-link
      >
    </template>

    <template v-slot:[`item.status_desc`]="{ item }">
      <span class="badge badge-secondary">
        {{ item.status_desc || "" }}
      </span>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-btn-toggle>
        <router-link
          class="
            white--text
            v-btn v-btn--contained v-btn--fab v-btn--round
            theme--light
            v-size--x-small
            success
          "
          :to="{ name: 'Order', params: { id: item.id } }"
        >
          <v-icon small class="white--text">mdi-eye</v-icon>
        </router-link>
      </v-btn-toggle>
    </template>
  </v-data-table>
</template>


<script>
import { mapState } from "vuex";
export default {
  props: {
    orders: {
      type: Array,
    },
  },
  computed: {
    ...mapState("orders", ["editorOrdersTableHeaders"]),
  },
};
</script>