<template>
  <div class="text-left">
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <div v-else>
      <BaseHeader
        :title="`${profile.type_desc || 'User'} Profile`"
      ></BaseHeader>

      <div class="card has-shadow mt-5">
        <div class="card-body">
          <div class="media">
            
            <div class="media-body">
              <div class="d-flex align-items-sm-center align-items-start flex-sm-row flex-column justify-content-sm-between justify-content-start ">
                <div class=" d-flex border-right-sm mr-3 align-items-center justify-content-start flex-row  w-50 ">
                  <div class="">
                    <div class="  mr-2 p-sm-5  p-4  mb-sm-4  mb-2  rounded-full text-capitalize bg-slate-200 dark:bg-darkmode-400 flex items-center justify-center text-base font-medium">
                      {{profile.first_name.charAt(0) || ""}}{{profile.last_name.charAt(0) || ""}}
                    </div>
                  </div>
                  <div class="d-flex flex-column mx-2 align-items-start ">
                    <h3 class="mt-0 text-2xl font-medium ">
                      <!-- #{{ profile.id }} <br /> -->
                      {{ profile.first_name || "" }} {{ profile.last_name || "" }}
                    </h3>

                    <p class="my-1 d-flex align-items-center justify-content-start ">
                      <i class="icon-envelope mr-2"></i
                      >{{ profile.email || "" }}
                    </p>

                  </div>
                </div>
                <div class=" d-flex mx-sm-4 my-sm-0 my-4 align-items-center justify-content-start flex-row  w-50 ">

                  <div class="d-flex flex-column mx-2 align-items-start ">
                    <!-- <h4 class="mt-0 " >
                      <p class="text-primary" >#</p> {{ profile.id }} <br />
                    </h4> -->
                    <h4 class="my-1 ">
                      <span class="text-primary" >#</span>
                      {{ profile.id || "" }}
                    </h4>

                    <h4 class="my-1">
                      <i class="icon-phone mr-2 text-primary"></i
                      >{{ profile.phone || "" }}
                    </h4>

                  </div>
                  
                  <div class="d-flex flex-column px-4 align-items-start ">
                    <h4 class="my-1">
                      <i class="icon-pin mr-2 text-primary"></i>
                      {{ profile.country || "" }}
                    </h4>

                    <h4 class="my-1">
                      <i class="icon-link mr-2 text-primary"></i>
                      {{ profile.site || "" }}
                    </h4>

                  </div>
                </div>

              </div>
              
              <!--
              <p class="my-1">
                <i class="icon-pin mr-2 text-primary"></i>
                {{ profile.country || "" }}
              </p>
              <p class="my-1">
                <i class="icon-link mr-2 text-primary"></i>
                {{ profile.site || "" }}
              </p> -->
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 ">
        <v-card-title class="d-flex flex-row p-3 card">
          <h2 class="mt-3 card-title w-50 font-weight-black text-capitalize">{{ profile.type_desc || "User" }} Orders</h2>
          
          <v-spacer></v-spacer>
          <v-text-field
          v-model="$store.state.search"
            dense
            outlined
            label="Search"
            name="url"
            type="text"
            hide-details
          />
          <!-- <v-text-field
            v-model="$store.state.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            dense
            hide-details
            class="my-auto"
          ></v-text-field> -->
        </v-card-title>
        <editor-orders-table
          v-if="userType == userTypes.editor"
          :orders="orders"
        >
        </editor-orders-table>
        <user-orders-table v-else :orders="orders"></user-orders-table>
      </div>

      <!-- <div class="card has-shadow">
        <div class="card-body px-0"></div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import EditorOrdersTable from "../../components/tables/EditorOrdersTable.vue";
import UserOrdersTable from "../../components/tables/UserOrdersTable.vue";

export default {
  name: "WriterProfile",
  data: () => ({}),

  components: {
    UserOrdersTable,
    EditorOrdersTable,
  },

  computed: {
    ...mapState("writers", ["loading"]),
    ...mapGetters("writers", ["profile", "orders", "wallet_entires"]),
    ...mapGetters("auth", ["userType", "userTypes"]),
  },

  methods: {
    ...mapActions("writers", ["getWriter"]),
  },

  mounted() {
    this.getWriter(this.$route.params.id)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  },
};
</script>
